import {useEffect, useState} from 'react'
export const Contents = () =>{
 const [contents, setContents] = useState()
    useEffect(()=>{
        const h2s = document.querySelectorAll('h2')
        const conts = Array.from(h2s).map((h2,i)=>{
            return(<li key={i}><a href={`#${h2.id}`}>{h2.innerText}</a></li>)
        })
        setContents(conts)
    },[])
    return(
        <>
        <h1>Contents</h1>
                    <ol className="contents">
                        {contents}
                    </ol>
        </>
    )
}