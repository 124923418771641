import React from 'react';
import {getScreenSizes} from '../../utils/helpers'
import './style.css'
function ScaleBar(props) {
  const {scale,mapData}=props

  const unit = ()=>{

    let base = "N/A"
    if(mapData&&mapData.units){
      base = mapData.units.split(",")[0].toLowerCase()
    }else{
      base = 'ft'
    }
    
    switch(base){
      
      default:return "N/A";
      case "ft": return "feet"
      case "in": return "inches"
      case "m": return "meters"
      case "km": return "kilometers"
      case "mi":return "miles"
    }
  } 


  function isOdd(num) { return num % 2;}
      const {screenWidth,screenHeight} = getScreenSizes()
      const maxDim = screenWidth>screenHeight?screenWidth:screenHeight
    const xNum = Math.ceil(maxDim/2/96)*2+1
    let xArry = [];
    for (let i=0;i<xNum;i++) xArry.push(i-(xNum-1)/2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scaleBars = xArry.map((x,i)=>{
      const style = {
        width:`96px`,
      }
      const halfBarStyleA = {
        backgroundColor:isOdd(x)?"white":"black"
      }
      const halfBarStyleB = {
        backgroundColor:isOdd(x)?"black":"white"
      }
      return(
        <div className="scaleBar" key = {i} style={style}>
          <div className="halfBar" style={halfBarStyleA}></div>
          <div className="halfBar" style={halfBarStyleB}></div>
        </div>
      )
    })
     const scaleVals = xArry.map((x,i)=>{
      const style = {
        width:`96px`,
      }
      return(<div key={i} style={style} className="scaleVal">{x===0?unit():x*(isNaN(scale)?40:scale)}</div>)
     }) 
    

  
  return (
    <div id="scaleBar">
      <div id="scales">{scaleBars}</div>
      <div id="scaleVals">{scaleVals}</div>
    </div>
    
  );
}

export default ScaleBar;
 