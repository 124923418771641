import layers from '../../assets/help/layers.png'
export const SvgLayeringReqs = () =>{
    return(
        <>
        <h2 id="2">SVG Layering and Content Requirements</h2>
                    <p><i>CaveView</i> requires your map to have a specific layering structure and layer names for all features to be useful.</p>
                    <p>Below is an example of a layer structure for an example map in Adobe Illustrator. The exact order of the layers is not important so long as it matches the 
                        desired final visual product.
                    </p>
                    <img src={layers} className="small" alt="Adobe Illustrator Layers"></img>
                    <h3>Summary of Layer Requirements</h3>
                    <p> *** indicates that this must be the <i>only</i> word in the layer name</p>
                    <p> In <i>ALL</i> instances the word in quotes must be the FIRST word in the layer</p>
                    <ul>
                        <li>data*** - required - case insensitive</li>
                        <li>"text" - optional - case insensitive</li>
                        <li>"Surface" - optional - case insensitive</li>
                        <li>Main Layers - required - case insensitive</li>
                        <li>"Leads" - optional - case insensitive</li>
                        <li>"Survey" - optional - case insensitive</li>
                        <li>"Text" - optional - case insensitive</li>
                        <li>Drop Heights*** - optional - case insensitive</li>
                        <li>Ceiling Heights*** - optional - case insensitive</li>
                        <li>"Placenames" - optional - case insensitive</li>
                        <li>Cross-sections*** - optional - case insensitive</li>
                        <li>"Details" - optional - case insensitive</li>
                    </ul>
                    <p>Starting from the top:</p>
                    <h3 id="2-1">1. The "data" layer <i>(Required)</i></h3>
                    <p>The "data" layer contains all of the metadata information you would like to include with your map. 
                        It will not be visible in the final map display in <i>Cave View</i>.
                    </p>
                    <p>This layer contains text elements which the application analyzes for information.  For a full explanation
                        on how to use the data layer see <a href="#4">DataLayer Formats</a>. </p>
                    <h3>2. The Text Layer</h3>
                    <p>The text layer contains any text and graphics that the cartographer wishes to have permanently displayed on the map.
                        This layer is optional, but if included it will be always visible, you can not turn it off.
                        As well, this text layer is not analyzed for place names.
                    </p>
                    <h3>3. Surface Level</h3>
                    <p>This layer contains above ground elements like roads, trails, and property boundaries.  Multiple Surface Layers can be included as <i>CaveView</i> only looks for the (non-case sensitive) word "Surface" in the layer name.</p>
                    <p>By default the Surface layer(s) are not visible when the application is launched.</p>
                    <h3>4. Upper/Middle/Lower/Satellite Cave Layers <i>(Required)</i></h3>
                    <p>These are the main layers for the cave map.  Each of these layers is essentially a cave map by itself and allows the user to turn on and off large sections of the map.</p>
                    <p>For a multi-level cave these layers may look like the example: Upper, Middle, Lower</p>
                    <p>For large cave systems with multiple section, each section may be a layer.  For instance, the Mammoth Cave System may include layers for Mammoth Cave, Crystal Cave, Unknown Cave, etc.</p>
                    <p>Within each layer are a series of sublayers which contain the primary map information.  </p>
                    <h4>5. Leads Sublayer</h4>
                    <p>The leads sublayer contains leads from this layer of the map you want <i>Cave View</i> to catalog and be able to reference.  For instructions on how to use the Lead layer see <a href="#5">Cataloging Leads</a>. </p>
                    <h4>6. Survey Sublayer</h4>
                    <p>The survey sublayer contains the survey plots for this layer of the map.  You can use any survey plot you want.  <i>CaveView</i> searches for the text elements associated with the stations for cataloging.  If no text elements exist, then <i>CaveView</i> will not find  your survey stations.</p>
                    <h4>7. Text Sublayer</h4>
                    <p>This sublayer contains the text elements for the main layer.  Any text elements left in this layer and not in the sublayers will be excluded from cataloging.</p>
                    <h5>8. Drop Heights Sublayer</h5>
                    <p>This sublayer contains the drop heights for the Text Layer and allows <i>CaveView</i> the ability to toggle them on and off.</p>
                    <h5>8. Ceiling Heights Sublayer</h5>
                    <p>This sublayer contains the ceiling heights for the Text Layer and allows <i>CaveView</i> the ability to toggle them on and off.</p>
                    <h5>9. Placenames Sublayer</h5>
                    <p>This sublayer contains the placenames for the main layer to be cataloged and referenced by <i>CaveView</i></p>
                    <h4>10. Cross-Sections Layer</h4>
                    <p>This sublayer contains the cross-sectional profiles.</p>
                    <h4>11. The Details Sublayers</h4>
                    <p>These layers include any extra detail in the map you wish to turn on and off - essentially reducing your cave map to "railroad tracks" for easier viewing of survey stations and leads.
                    </p>
                    <p>Typical detail layers include:</p>
                    <ul>
                        <li>Speleothems</li>
                        <li>Ceiling Ledges</li>
                        <li>Slopes</li>
                        <li>Floor details like mud and gravel</li>
                        <li>Water</li>
                        <li>Rocks and boulders</li>
                        <li>Floor ledges</li>
                    </ul>
                    <h4>12. Remaining Sublayers in the Main Layer</h4>
                    <p>The remaining sublayers contain elements that are always visible when the main layer itself is visible.</p>
                    <h3>13. "Extra stuff"</h3>
                    <p>Any layers that have visibility turned off in Illustrator will not be exported with the SVG.</p>
        </>
    )
}