import React from "react";

import './style.css';
import { moveTo } from "../../utils/moveAndZoom";
export const StartPositionModal = (props)=>{

    const {datum,entrances,setStyle} = props

    const handleClick = (e,x,y)=>{
       setStyle({opacity:"1"})
       document.querySelector("div.fullScreenModal").style.opacity="0"
       moveTo({x:x,y:y})
        setTimeout(()=>document.querySelector("#svgContainer").style.opacity="1",200)
        setTimeout(()=>{
       
            const startLocModal = document.querySelector("div.startPositionModal")
     
            if (startLocModal) startLocModal.parentElement.remove()
        },500)
    }
    const WholeMap = ()=>{
        return(<div className='modalSelector' onClick={(e)=>handleClick(e,0.5,0.5)}>CENTER</div>)
    }
    const DatumSelector = ()=>{
        if(datum) return(<div onClick={(e)=>handleClick(e,0.5,datum.y)} className='modalSelector'>
            <div>SYSTEM DATUM</div>
            <div>Station {datum.name}</div>
            </div>)
    }
    const entranceSelectors = 
        entrances.map((ent,i)=>{
            return(<div key={i} onClick={(e)=>handleClick(e,ent.x,ent.y)} className='modalSelector'>
            <div>{ent.name}</div>
            </div>)
        })
    
   
        return( <div className="fullScreenModal">
            <div className="startPositionModal">
                <div>Please choose a starting location:</div>
                <WholeMap/>
                <DatumSelector/>
                {entranceSelectors}
            </div>
        </div>)
    
        
    

}