import react from 'react'

import './style.css'

export const launchToolTip = (props) =>{
    const {message,title} = props;

    const toolTip = document.createElement("div")
    toolTip.className = "toolTipContainer"
    const toolTipModal = document.createElement("div")
    toolTipModal.className = "toolTip"
    const toolTipHeader = document.createElement("div")
    toolTipHeader.className = "toolTipHeader"
    const toolTipTitle = document.createElement("div")
    toolTipTitle.className = "toolTipTitle"
    toolTipTitle.innerText = title
    const toolTipMessage = document.createElement("div")
    toolTipMessage.className = "toolTipMessage"
    toolTipMessage.innerText = message
    const toolTipExitButton = document.createElement("button")
    toolTipExitButton.className = "toolTipExitButton"
    toolTipExitButton.innerText = "+"
    toolTipExitButton.addEventListener("click",()=>document.querySelector("div.toolTipContainer").remove())
    toolTipHeader.appendChild(toolTipTitle)
    toolTipHeader.appendChild(toolTipExitButton)
    toolTipModal.appendChild(toolTipHeader)
    toolTipModal.appendChild(toolTipMessage)
    toolTip.appendChild(toolTipModal)
    document.querySelector("div#root").appendChild(toolTip)

}

export const ToolTip = (props) =>{
    const {toolTipInfo} = props
    return( <button onClick={()=>launchToolTip(toolTipInfo)} className="info">i</button>)
}