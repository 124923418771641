export const usernameInfo = {
    title: "Owner Username",
    message:`Username for the GitHub user hosting the map file.`
}
export const repoInfo = {
    title: "Repo Name",
    message:`Name of the GitHub repository hosting the map file.`
}
export const pathInfo = {
    title: "Path",
    message:`Path through the GitHub repository to the map file.  This is available to copy from the file itself in GitHub.`
}
export const authInfo = {
    title: "Personal Access Token",
    message:`The personal access token is generated by the GitHub account owner to allow access by external third party applications into their repository.  Your personal access token will be deleted after the map is fetched.  Your other data will remain in local storage until you delete the map.  You will need to re-enter your personal access token to update the map in the future.  This space can be left blank if no authentication is needed.`
}