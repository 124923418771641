import React from 'react';
import '../../../styles/buttons.css'
import './style.css'
export default function GridButton(props) {

  const {size,handle,state} = props
  const clickEvent = ()=>{
    handle(!state)
  }
  return (
    <button id={`${size}GridButton`} className={`button icon ${state?"active":"inactive"}`} onClick={()=>clickEvent()}></button>
  );
}



