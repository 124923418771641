import {useState,useEffect} from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Home } from "./pages/Home";
import { AddEditMapFromGitHub } from "./pages/AddEditMapFromGitHub";
import { About } from "./pages/About";
import { BetaTools } from "./pages/BetaTools";
import { Help } from "./pages/Help";
import { MapViewer } from './pages/MapViewer/MapViewer';

import { SplashScreen } from './components/SplashScreen/SplashScreen';

import './App.css';

function App() {

  const [mapObj,setMapObj] = useState("")
  const [version,setVersion] = useState("Version 1.1.2")
  const [copy,setCopy] = useState("© 2024 Ryan R. Maurer")
  const [hasShownSplash,setHasShownSplash] = useState(false)
 
  return (
    <>
  
    <BrowserRouter>
    <Routes>
      <Route path="mymaps" element={<Home  copy={copy} version={version} mapObj={mapObj} setMap={setMapObj}/>}/>
      <Route index element={<SplashScreen hasShownSplash={hasShownSplash} setHasShownSplash={setHasShownSplash} copy={copy} version={version}/>}/>
      <Route path="addEditMapFromGitHub" element={<AddEditMapFromGitHub/>}/>
      <Route path="about" element={<About copy={copy} version={version}/>}/>
      <Route path="betatools" element={<BetaTools/>}/>
      <Route path="help" element={<Help/>}/>
      <Route path="mapViewer" element={<MapViewer mapObj={mapObj}/>}/>
    </Routes>
   </BrowserRouter>
   
  
    </>
   
  );
}

export default App;
