import React from 'react';
import {getScreenSizes} from '../../utils/helpers'
import './style.css'
function MinorGrid(props) {
  const {opacity}=props
  const resolution = 96

  
      const {screenWidth,screenHeight} = getScreenSizes()
      const maxDim = screenWidth>screenHeight?screenWidth:screenHeight
      const xNum = (Math.ceil(maxDim/2/resolution)*2+1)*10
      let xArry = [];
      for (let i=0;i<xNum;i++) xArry.push((i-(xNum-1)/2)*(resolution/10)+maxDim/2)
        const style = {stroke:"rgb(186, 255, 255)",
    strokeWidth:"1"}
      const minorGridColumns = xArry.map((x,i)=>{
       return(<line key={i} x1={(x+5).toString()} x2={(x+5).toString()} y1="0" y2={maxDim.toString()} style={style} ></line>)
      })
      const minorGridRows = xArry.map((x,i)=>{
        return(<line key={i} y1={(x-5).toString()} y2={(x-5).toString()} x1="0" x2={maxDim.toString()} style={style} ></line>)
       })
        
         

       const gridStyle = {opacity:opacity?"1":"0"}    

  
  return (
    <div id="minorGridContainer" style={gridStyle}>
        <svg id="minorGrid" height={maxDim.toString()} width={maxDim.toString()} xmlns="http://www.w3.org/2000/svg">
            {minorGridRows}
            {minorGridColumns}
        </svg>
    </div>
  );
}

export default MinorGrid;
 