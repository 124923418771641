import oneOne from '../../assets/help/1-1.png'
export const SetupGitHubRepoCredentials = () =>{
    return (
        <>
        <h2 id="1">Setup GitHub Repo Credentials</h2>
                    <p>In order to acquire the map file from your GitHub Repo you will need to 
                        configure your repository to allow this application to access it remotely.
                    <br/><br/>This can be achieved by going into your GitHub Account Settings and generating
                        an access key specifically for your repository.
                    </p>
                    <h3>Procedure</h3>
                    <ol>
                        <li>Log into your GitHub Account.</li>
                        <li>Click on your profile photo and open up the Setting page.
                            <img src={oneOne} className="small" alt="Screenshot from GitHub"></img>
                        </li>
                        <li>In your settings navigate to Developer Settings at the bottom of the side bar.</li>
                        <li>In Developer Settings click on Personal Access Tokens in the side bar and then select "Fine-grained tokens".</li>
                        <li>Generate a new token, you'll be prompted for your password.</li>
                        <li>This token serves as a password to access parts of your repository.  It is up to you to decide how long you want it to be active for.</li>
                        <li>At a minimum you <b>must</b> check "Only select repositories".  Select the repository containing the map file.
                        </li>
                        <li>Under Repository permissions you <b>must</b> allow Read-only or Read and write permissions for the Contents.</li>
                        <li>Generate a personal access token.  Record it somewhere as you will never be shown it again.</li>
                        <li><i>Reccomended Practices:</i> Create a repository with the map file as the <i>only</i> file in it.  Generate an access token for 7 days, long enough for your team to gather the map and updates you've made.</li>
                        <li>Navigate to the map file within your repository, click on the "..." button and Copy Path. </li>
                        <li>Send the following information to anyone you want to download your map:
                            <ol>
                                <li>Your account username.</li>
                                <li>Your repository name</li>
                                <li>The Copied Path to the map file</li>
                                <li>The personal access token you generated for that repository.</li>
                            </ol>
                        </li>
                    </ol>
        </>
    )
}