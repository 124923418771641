import React, {useState,useEffect} from 'react'
import './style.css'
import { Link } from "react-router-dom";
import { SingleLineInput } from '../components/SingleLineInput/SingleLineInput';
import { usernameInfo, repoInfo, authInfo,pathInfo } from '../components/ToolTip/toolTipData';
import { TextAreaInput } from '../components/TextAreaInput/TextAreaInput';
import { fetchFromGithub } from '../utils/fetchFunctions';

export const AddEditMapFromGitHub = () =>{
    const [username,setUsername] = useState("")
    const [reponame,setReponame] = useState("")
    const [path,setPath] = useState("")
    const [auth,setAuth] = useState("")
    const [ready,setReady] = useState(false)
    const [reqData,setReqData] = useState({})
    const patPlaceholderString = " This space can be left blank if no authentication is needed.  Your personal access token will be deleted after the map is fetched.  Your other data will remain in local storage until you delete the map.  You will need to re-enter your personal access token to update the map in the future."
    useEffect(()=>{
        if(username!==""&&reponame!==""&&path!=="") setReady(true)
        else setReady(false)
        
        const data = {username:username,repo:reponame,path:path,auth:auth}
        setReqData(data)
    },[username,reponame,path,auth])
    
    return(
        <div className="bodyContainer">
            <div className="addEditHeader">
            <div>Add Map From GitHub</div>
                <Link to="/mymaps">Cancel</Link>
            </div>
            <SingleLineInput title="Owner Username" toolTipInfo={usernameInfo} setState={setUsername} placeholder="(REQUIRED)"/>
            <SingleLineInput title="Repo Name" toolTipInfo={repoInfo} setState={setReponame} placeholder="(REQUIRED)"/>
            <SingleLineInput title="Path" toolTipInfo={pathInfo} setState={setPath} placeholder="(REQUIRED)"/>
            <TextAreaInput title="Personal Access Token" toolTipInfo={authInfo} setState={setAuth} placeholder={patPlaceholderString}/>
            <button onClick={()=>fetchFromGithub(reqData)} className={`addEdit ${ready?"":"disabled"}`}>Get Map</button>
        </div> 
    )
   
}