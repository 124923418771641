import React from 'react';

import '../../../styles/buttons.css'
import './style.css'
export default function HomeButton() {

  

  const clickEvent = ()=>{
    window.location.pathname="/mymaps"
  }
  return (
    <button id="home" className={`button icon active`} onClick={()=>clickEvent()}></button>
  );
}



