import React from 'react';
import './style.css';
import '../../../styles/buttons.css'

export default function ZoomButton({direction,status,handleClick, handleStartCounter, handleStopCounter}) {
  return (
    <button onTouchCancel={handleStopCounter} onTouchStart={handleStartCounter} onTouchEnd={handleStopCounter} onMouseDown={handleStartCounter} onMouseUp={handleStopCounter} onMouseLeave={handleStopCounter} onClick={handleClick} className={`button ${direction} ${status}`}></button>
  );
}



