import React, {useState,useEffect} from 'react'
import './style.css'
import {Link} from "react-router-dom";

export const BetaTools = (props) =>{
    const [compass, setCompass] = useState(null)
    const [inc, setInc] = useState(null)
    const [lat,setLat] = useState(0)
    const [lon,setLon] = useState(0)
    const [acc,setAcc] = useState(0)
    const [err,setErr] = useState("")
    const startOrient = () =>{
        if ( typeof( DeviceMotionEvent ) !== "undefined" && typeof( DeviceMotionEvent.requestPermission ) === "function" ) {
            // (optional) Do something before API request prompt.
            DeviceMotionEvent.requestPermission()
                .then( response => {
                // (optional) Do something after API prompt dismissed.
                if ( response == "granted" ) {
                    window.addEventListener( "deviceorientation", (e) => {
                        const compassHeading = e.alpha

                        setCompass(Math.trunc(compassHeading*100)/100)
                        setInc(Math.trunc(e.gamma*100)/100)
                        
                    })
                }
            })
                .catch( console.error )
        } else {
            alert( "DeviceMotionEvent is not defined" );
        }
      
    }
    const showPosition = (position) =>{
        setLat(position.coords.latitude)
        setLon(position.coords.longitude)
        setAcc(position.coords.accuracy)
       
    }

    const showError = (error) => {
        switch(error.code) {
          case error.PERMISSION_DENIED:
           setErr("User denied the request for Geolocation.")
            break;
          case error.POSITION_UNAVAILABLE:
           setErr("Location information is unavailable.")
            break;
          case error.TIMEOUT:
           setErr("The request to get user location timed out.")
            break;
          case error.UNKNOWN_ERROR:
           setErr("An unknown error occurred.")
            break;
        }
      }

    const startGPS = () =>{
        navigator.permissions.query({name:"geolocation"})
        .then((res)=>{
            if (res.state === 'denied'){
                
            }
        })
        setInterval(()=>{
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(showPosition,showError)
            }
        },1000)
    }
    return(
        <div className="bodyContainer">
            <div className="homeHeader">
                <div>Beta Tools</div>
                <Link to="/addEditMapFromGitHub">+</Link>
            </div>
            <div className='motionOrient'>
                <Link to="/survey">Survey</Link>
                <div>Motion Orientation</div>
                <button onClick={startOrient}>Start/Stop</button>
                <div>Az: {compass}</div>
                <div>Inc: {inc}</div>
                <button onClick={startGPS}>GPS</button>
                <div>Lat: {lat}</div>
                <div>Lon: {lon}</div>
                <div>Acc: {acc}</div>
                <div>Err: {err}</div>
            </div>
            <div className="homeFooter">
                <Link to="/about">About</Link>
                <Link to="/mymaps">My Maps</Link>
                <Link to="/help">Help</Link>
            </div>
        </div>
    )
}