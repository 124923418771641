
export const ExportFromInkscape = () =>{
    return(
        <>
        <h2 id="3">Exporting the SVG from Inkscape</h2>
        <p>Exporting the SVG from Inkscape is even more simple gthan exporting from Illustrator.</p>
        <ol>
            
        </ol>
        </>
    )
}