import React from 'react';
import './style.css'
function TitleBar(props) {
  const {mapData} = props
  return (
    <div id="titleBar">
      {mapData.name} - Version {mapData.version} - {mapData.date}
    </div>
    
  );
}

export default TitleBar;
 