import React from 'react'
import './style.css'
import {Link } from "react-router-dom";
import { Contents } from '../components/Help/Contents';
import { SetupGitHubRepoCredentials } from '../components/Help/SetupGitHubRepoCredentials';
import { SvgLayeringReqs } from '../components/Help/SvgLayeringReqs';
import { ExportFromIllustrator } from '../components/Help/ExportFromIllustrator';
import { ExportFromInkscape } from '../components/Help/ExportFromInkscape';
export const Help = () =>{
    
    return(
        <div className="bodyContainer">
            <div className="homeHeader">
                <div>Help</div>
            </div>
    
            <div className="splashScreen help">
                <div className="helpBody">
                    <Contents/>
                    <SetupGitHubRepoCredentials/>
                    <SvgLayeringReqs/>
                    <ExportFromIllustrator/>
                    <ExportFromInkscape/>
                </div>
            </div>
            <div className="homeFooter">
                <Link to="/about">About</Link>
                <Link to="/mymaps">My Maps</Link>
            </div>
        </div>
    )
}