import { ToolTip } from '../ToolTip/ToolTip';
export const SingleLineInput = (props)=>{
    const {toolTipInfo,setState,placeholder,title} = props
    return(<div className="inputGroup">
    <div>
        <div className="label"  >{title}</div>
       <ToolTip toolTipInfo={toolTipInfo}/>
    </div>
    <input type="text" onInput={(e)=>setState(e.target.value)} placeholder={placeholder}></input>
</div>)
}