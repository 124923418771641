import React from 'react'
import './style.css'
import Arrow from './arrow.svg'


export const NorthArrow = (props) =>{
    const {north} = props
    const style = {
        transform:`rotate(${north}deg)`
    }

    


    return (
        <div id="northArrow">
            <img style={style}  src={Arrow} alt="north arrow" className="northArrowSVG"/>
            <div>{north}°</div>
        </div>
    )
}

