import {useState,useEffect} from 'react'
import './SplashScreen.css'
import whiteLogo from '../../logoWhite.svg'
export const SplashScreen = ({copy,version,hasShownSplash,setHasShownSplash}) =>{
    const [titleOpa,setTitleOpa] = useState("0")
    const [splashOpa,setSplashOpa] = useState("1")
 

    const splashStyle = {
        opacity:splashOpa
    }
    const titleStyle = {
        opacity:titleOpa
    }
    let animRunning = false
    useEffect(()=>{
        if(!animRunning){
      
            animRunning = true;
            setTimeout(()=>{
                setTitleOpa("1")
            },500)
            setTimeout(()=>{
                setSplashOpa("0")
            },2000)
            setTimeout(()=>{
                window.location.pathname="/mymaps"
            },3000)
       
       
        }
    },[])

    return (
        <div id="splashScreen" style={splashStyle}>
            <div id="title" style={titleStyle}>
                <img src={whiteLogo} alt='whiteLogo'/>
                <div className='title'>KarstGIS</div>
                <div className='subtitle'>Software for Karst Mapping</div>
                <div className='version'>
                    {version}
                </div>
                <div className='copy'>
                    {copy}
                </div>
            </div>
        </div>
    )
}