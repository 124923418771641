import React, {useEffect,useState} from "react";
import ReactDOM from 'react-dom/client';

import { Octokit } from '@octokit/rest';

import {authInfo } from '../ToolTip/toolTipData';
import { addMap, deleteMap } from "../../utils/indexedDB";
import { getDataObject } from "../../utils/svgParsingFunctions";
import { TextAreaInput } from '../TextAreaInput/TextAreaInput';
import loading from '../../assets/graphics/loading.png'
import errorImg from '../../assets/graphics/errorX.svg'
import successImg from '../../assets/graphics/success.svg'
import '../../utils/fetchingStyles.css'
import './AreYouSure.css'
import '../../pages/style.css'


export const RefreshModal = (props) =>{
    const {data,currentEntry}=props

    const auth = !!data.auth
    const url = `https://api.github.com/repos/${data.username}/${data.repo}/${data.path}`
    const [imgSrc,setImgSrc] = useState(loading)
    const [imgClass,setImgClass] = useState("fetching")
    const [resultClass,setResultClass] = useState("error")
    const [resultName,setResultName] = useState("")
    const [resultDesc,setResultDesc] = useState("")
    const [resultMess,setResultMess] = useState("")
    const [showButton,setShowButton] = useState("0")

    const handleReturn = ()=>{
        if(resultClass==="success"){
         window.location.pathname = "/mymaps"
        }else{
         document.querySelector("div.fetchingModalContainer").remove()
        }
    }

    useEffect(()=>{
 
         const octokit = new Octokit({
             auth:data.auth,
             baseUrl: 'https://api.github.com',
             mediaType:{format:'raw'}
         })
         octokit.repos.getContent({
             owner: data.username,
             repo: data.repo,
             path: data.path,
             headers: {
                 "Accept": "application/vnd.github.v3.raw"
             }
           })
        .then(res=>
         {
             if(res.status===200){
                 const parser = new DOMParser();
                 const svgDoc = parser.parseFromString(
                 res.data,
                 "image/svg+xml",
                 );
                 const dataLayer = svgDoc.documentElement.querySelector('g[id*="data"],g[id*="Data"]')
                 let dataObject = {}
                 if(dataLayer){
                     dataObject = getDataObject(dataLayer)
                 }
                 const key = Math.round(Math.random()*10000)
                 const dbEntry = {
                     index:key,
                     mapdata: res.data,
                     date_mod: res.headers['last-modified'],
                     size:res.headers['content-length'],
                     src:'GitHub',
                     un:data.username,
                     repo:data.repo,
                     path:data.path,
                     title:dataObject.obj.title?dataObject.obj.title:"N/A",
                     date_pub:dataObject.obj.date?dataObject.obj.date:"N/A",
                     version:dataObject.obj.version?dataObject.obj.version:"N/A",
                 }     
                 return {dbEntry:dbEntry,currentEntry:currentEntry}
             }else throw new Error(res)
             }
        )
        .then((data)=>{
            const {currentEntry,dbEntry} = data
            deleteMap(currentEntry.index)
            addMap(dbEntry)
            return {dbEntry:dbEntry}
        })
        .then((results)=>{
         
            const {dbEntry} = results
           
                setResultClass("success")
                setImgSrc(successImg)
                setImgClass("")
                setResultName("Success!")
                setResultMess(`${dbEntry.title} - Version ${dbEntry.version} - ${dbEntry.date_pub}`)
                setResultDesc("Map has been updated!")
                setShowButton("1")
            
        })
        .catch((err)=>{
         setShowButton("1")
         setImgSrc(errorImg)
         setImgClass("")
         setResultName(err.name)
         setResultMess(err.message)
         setResultDesc(err.status)
     })
    },[])

    const style = {opacity:showButton}

   return(
        <div className="fetchingModal">
            <div className="fetchingModalHeader">Fetching map from:</div>
            <div className="fetchingModalMessage">{url}</div>
            <div className="fetchingModalMessage">{`Authentication Included: ${auth?"Yes":"No"}`}</div>
            <img src={imgSrc} id="statusImage" className={imgClass} alt="Status icon"/>
            <div id="fetchingModalResult" className={resultClass}>
                <div className='resultName'>{resultName}</div>
                <div className='resultDescription'>{resultDesc}</div>
                <div className='resultMess'>{resultMess}</div>
                <button className={resultClass} style={style} onClick={handleReturn}>Return</button>
            </div>
        </div>
   )
}


const refreshFromGithub = (props) =>{
    const {currentEntry,data} = props
    const root = document.getElementById("root")
    const conatiner = document.createElement("div")
    conatiner.className = "fetchingModalContainer"
    root.appendChild(conatiner)
    const spot = ReactDOM.createRoot(conatiner);
    spot.render(<RefreshModal currentEntry={currentEntry} data={data}/>)
}

export const RefreshMapFromGitHub = (props) =>{
    const {currentEntry} = props
    const {path,repo,un,title} = currentEntry
    const patPlaceholderString = " This space can be left blank if no authentication is needed.  Your personal access token will be deleted after the map is fetched.  Your other data will remain in local storage until you delete the map.  You will need to re-enter your personal access token to update the map in the future."
    const [auth,setAuth] = useState("")
    const [reqData,setReqData] = useState({})
    useEffect(()=>{
        
        
        const data = {username:un,repo:repo,path:path,auth:auth}
        setReqData(data)
    },[auth])
    return(
        <div className="bodyContainer refreshModal">
            <div className="addEditHeader">
            <div>Update Map From GitHub</div>
            <a href="/">Cancel</a>
            </div>
            <div>{title}</div> 
            <div>Owner: {un}</div>
            <div>Repo: {repo}</div>
            <div>Path: {path}</div>
            <TextAreaInput title="Personal Access Token" toolTipInfo={authInfo} setState={setAuth} placeholder={patPlaceholderString}/>
            <button onClick={()=>refreshFromGithub({data:reqData,currentEntry:currentEntry})} className={`addEdit `}>Refresh Map</button>
        </div> 
    )
   
}


export const launchRefreshModal = (props) =>{

    
    const {index} = props
   
    let db;
    const openReq = indexedDB.open('caveView')
    
   openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["caveviewmaps"], "readwrite");

    const store = tx.objectStore('caveviewmaps');

    const request = store.get(index)

    request.onsuccess = (e) =>{ 

        const currentEntry = (request.result)
        const root = document.getElementById("root")
        const conatiner = document.createElement("div")
        conatiner.className = "bodyContainer"
        conatiner.id="refreshContainer"
        root.appendChild(conatiner)
        const spot = ReactDOM.createRoot(conatiner);

        if (currentEntry.src==="GitHub") spot.render(<RefreshMapFromGitHub currentEntry={currentEntry}/>)
        
    }
}
   

    
}