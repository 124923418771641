import { launchErrorModal } from "../components/Modals/ErrorModal";
import { startsWithAny } from "./helpers";
import { setInkscapeIds, parseMapData, checkForMapData } from "./svgParsingFunctions";
export const setLayer = props =>{
    const {setBodyLayer,setSurveyLayers,setCeilingLayers,setDetailLayers,setDropLayers,setLeadLayers,setMainLayers,setTextLayers, svgObj} = props
    const svgChildLayers = svgObj.children;
    let bodyLayer
    Array.from(svgChildLayers).forEach(layer=>{
       if(layer.tagName==="g") {
        bodyLayer = layer
       }
     })
     if(bodyLayer) {
        setBodyLayer(bodyLayer)
        const layers = Array.from(bodyLayer.children);
        let mainArray = []
        let ceilingArray = []
        let detailArray = []
        let dropArray = []
        let leadArray = []
        let surveyArray = []
        let textArray = []
        layers.forEach(layer=>{
            const id = layer.id.toLowerCase()
            const isLayerToHide = startsWithAny(id,["text","data","underlayer","surface"],false)
            layer.style.opacity=isLayerToHide?"0":"1"
            mainArray.push({name:layer.id.replaceAll("_"," "),state:isLayerToHide?0:1})
            if(layer.querySelector('g[id^="survey"],g[id^="Survey"]')) layer.querySelector('g[id^="survey"],g[id^="Survey"]').style.opacity="0"
            surveyArray.push(!layer.querySelector('g[id^="survey"],g[id^="Survey"]')?null:0)
            
            

            if(layer.querySelector('g[id^="text"],g[id^="Text"]')){ 
                layer.querySelector('g[id^="text"],g[id^="Text"]').style.opacity="1"
                leadArray.push(!layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="lead"],g[id^="Lead"]')?null:0)
                if(layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="lead"],g[id^="Lead"]')) layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="lead"],g[id^="Lead"]').style.opacity="0"
            }
            textArray.push(!layer.querySelector('g[id^="text"],g[id^="Text"]')?null:1)
            if(layer.querySelector('g[id*="detail"],g[id*="Detail"]')) {
                Array.from(layer.querySelectorAll('g[id*="detail"],g[id*="Detail"]')).forEach(l=>{
                    l.style.opacity="1"
                })
            }
            detailArray.push(!layer.querySelector('g[id*="detail"],g[id*="Detail"]')?null:1)
            if(!!layer.querySelector('g[id^="text"],g[id^="Text"]')){
                if(layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="ceiling"],g[id^="Ceiling"]')) layer.querySelector('g[id^="ceiling"],g[id^="Ceiling"]').style.opacity="1"
                ceilingArray.push(!layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="ceiling"],g[id^="Ceiling"]')?null:1)
                if(layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="drop"],g[id^="Drop"]')) layer.querySelector('g[id^="drop"],g[id^="Drop"]').style.opacity="1"
                dropArray.push(!layer.querySelector('g[id^="text"],g[id^="Text"]').querySelector('g[id^="drop"],g[id^="Drop"]')?null:1)
               
            }else
            {ceilingArray.push(null)
            dropArray.push(null)
          }
        })
        
        setMainLayers(mainArray)
        setSurveyLayers(surveyArray)
        setLeadLayers(leadArray)
        setTextLayers(textArray)
        setDetailLayers(detailArray)
        setCeilingLayers(ceilingArray)
        setDropLayers(dropArray)
        return bodyLayer
     }else{
        launchErrorModal("Error","Unable to parse SVG body layer, please send bug report and your SVG file.","/")
     }
 
}

export const setLayerStates = props =>{
    const {svgObj,setStateFuncs} = props
    const {setMainLayers,setDropLayers,setBodyLayer,setCeilingLayers,setMapString,setMapData,setSurveyLayers,setTextLayers,setDetailLayers,setLeadLayers} = setStateFuncs
    if(svgObj.hasAttribute('xmlns:inkscape')) setInkscapeIds(svgObj)
        const dataObj = parseMapData({svgObj:svgObj,setMapData:setMapData,setMapString:setMapString})
        const hasRequiredData = checkForMapData(dataObj)
        let warnings = []
        if (hasRequiredData === "No scale"){
            launchErrorModal("Error","SCALE is missing in the dataLayer.  This is a required value.","/")
        }else {
            warnings.push(hasRequiredData)
            const layerProps = {
                setMainLayers:setMainLayers,
                setLeadLayers:setLeadLayers,
                setCeilingLayers:setCeilingLayers,
                setDropLayers:setDropLayers,
                setDetailLayers:setDetailLayers,
                setBodyLayer:setBodyLayer,
                setSurveyLayers:setSurveyLayers,
                setTextLayers:setTextLayers,
                svgObj:svgObj
            }

            const bodyLayer = setLayer(layerProps)
            return {warnings:warnings,bodyLayer:bodyLayer,svgObj:svgObj,dataObj:dataObj}
        }
        return "ERROR"
}

export const updateLayerVisibility = (props) =>{
    if(document.querySelector("#svgContainer>svg")
    && document.querySelector("#svgContainer>svg").children[1]){
        const mainLayers = document.querySelector("#svgContainer>svg").children[1].children
        if(mainLayers){
        Array.from(mainLayers).forEach((layer,i)=>{
            
                if(props.target==="mainLayers"||props.target==="all"){
                    if(props.mainLayers[i]&& props.mainLayers[i].hasOwnProperty('state')) layer.style.opacity = props.mainLayers[i].state.toString()
                }
                if(props.target==="surveyLayers"||props.target==="allsublayers"){
                    const surveyLayers = layer.querySelector('g[id^="survey"],g[id^="Survey"]')
                    if(surveyLayers && surveyLayers.length!==0 && props.surveyLayers[i]!==null) surveyLayers.style.opacity = props.surveyLayers[i].toString()
                }
                if(props.target==="detailLayers"||props.target==="allsublayers"){
                    const detailLayers = layer.querySelectorAll('g[id*="detail"],g[id*="Detail"]')
                    if(detailLayers && detailLayers!==0 && props.detailLayers[i]!==null) Array.from(detailLayers).forEach(detail=>{
                        detail.style.opacity = props.detailLayers[i].toString()
                    })
                }
                if(props.target==="ceilingLayers"||props.target==="allsublayers"){
                    const textLayer = layer.querySelector('g[id^="text"],g[id^="Text"]')
                    if (textLayer){
                        const ceilingLayers = textLayer.querySelector('g[id^="ceiling"],g[id^="Ceiling"]')
                   
                        if(ceilingLayers && ceilingLayers!==0 && props.ceilingLayers[i]!==null) ceilingLayers.style.opacity = props.ceilingLayers[i].toString()
                    }
                    
                }
                if(props.target==="dropLayers"||props.target==="allsublayers"){
                    const textLayer = layer.querySelector('g[id^="text"],g[id^="Text"]')
                    if (textLayer){
                    const dropLayers = textLayer.querySelector('g[id^="drop"],g[id^="Drop"]')
              
                    if(dropLayers && dropLayers!==0 && props.dropLayers[i]!==null) dropLayers.style.opacity = props.dropLayers[i].toString()}
                }
                if(props.target==="textLayers"||props.target==="allsublayers"){
                    const textLayers = layer.querySelector('g[id^="text"],g[id^="Text"]')
              
                    if(textLayers && textLayers!==0 && props.textLayers[i]!==null) textLayers.style.opacity = props.textLayers[i].toString()
                }
                if(props.target==="leadLayers"||props.target==="allsublayers"){
                    const textLayer = layer.querySelector('g[id^="text"],g[id^="Text"]')
                    if(textLayer){
                    const leadLayers = textLayer.querySelector('g[id^="lead"],g[id^="Lead"]')
              
                    if(leadLayers && leadLayers!==0 && props.leadLayers[i]!==null) leadLayers.style.opacity = props.leadLayers[i].toString()
                    }
                    
                }
            })
            
        }
   
    }
    
   

}