export const startsWithAny = (string,array,casing) =>{

    for (let i=0;i<array.length;i++){
        const a = casing?array[i]:array[i].toLowerCase()
        const b = casing?string:string.toLowerCase()
        if(b.startsWith(a)) return true
    }
    return false
    
}

export const getScreenSizes = ()=>{ 
 
    return{screenWidth:window.outerWidth,screenHeight:window.outerHeight}
  
  }

  export const setIndex = (array,index,value) =>{
    let newArray = array
    if(value==="toggleLayer"){
      newArray[index].state = array[index].state*-1+1
    }else if(value==="focusLayer"){
      for (let i=0;i<newArray.length;i++){
        if(newArray[i].state!==null){
          newArray[i].state=0
        }
      }
      newArray[index].state = 1
    }
    else if(value==="toggle"){
      if(newArray[index]!==null)  newArray[index] = array[index]*-1+1
     
    }else{
    if(newArray[index]!==null) newArray[index] = value
    }
    return newArray
  }

  export const removeDuplicates = (arr) => {
    let unique = [];
    arr.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    return unique;
  }
  export const compareByName = ( a, b ) => {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  export const compileDataString = (data)=>{
    let string = ""
    Array.from(data.children).forEach(piece=>{
     if(!piece.innerHTML.startsWith("<tspan"))string += piece.innerHTML
    })
    return string
   }