import React from 'react';
import '../../../styles/buttons.css'
import './style.css'
export default function SubMenuButton({menu}) {
  const clickEvent = (target) =>{
    
    const allSubMenus = Array.from(document.querySelectorAll('div.subMenu'))
    const allSubButtons = Array.from(document.querySelectorAll('button.subMenuButton'))
    const currentStatus = document.querySelector(`div#subMenu_${target}`).classList.contains("open")
    if (!currentStatus){
      allSubMenus.forEach(menu=>{
        if(menu.id===`subMenu_${target}`) {
          menu.className = "subMenu open"
        }
          else {
            menu.className = "subMenu closed"
          }
      })
      allSubButtons.forEach(button=>{
        if(button.id===`${target}SubMenuButton`) {
          button.className = "button subMenuButton icon open"
        }
          else {
            button.className = "button subMenuButton icon closed"
          }
      })
    }else{
      document.querySelector(`div#subMenu_${target}`).className = "subMenu closed"
      document.querySelector(`button#${target}SubMenuButton`).className="button subMenuButton icon closed"
    }
  }
  return (
    <button onClick={()=>clickEvent(menu)} id={`${menu}SubMenuButton`} className="button subMenuButton icon closed"></button>
  );
}



