import React from 'react'
import './style.css'
import {Link } from "react-router-dom";
import logo from '../logo.svg'
import logotext from '../logotext.svg'
export const About = ({copy,version}) =>{
    
    return(
        <div className="bodyContainer">
            <div className="homeHeader">
                <div>About</div>
            
            </div>
    
            <div className="splashScreen">
                <img src={logotext} alt="Text logo"/>
                <div className="release">Release: {version}</div>
                <img src={logo} alt="logo"/>
                <div className='copyright'>
                    {copy}
                </div>
                <div className='text'>
                    Acknowledgements: </div>
                    <div className='text'>
                    Hope Brooks, The Discord Grotto, Todd Hancock, Eric C. Landgraf,  Robert Martin, Linda Pook, William Storage.
                </div>
            </div>
            <div className="homeFooter">
                <Link to="/mymaps">My Maps</Link>
                <Link to="/help">Help</Link>
            </div>
        </div>
    )
}