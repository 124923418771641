
export const ExportFromIllustrator = () =>{
    return(
        <>
        <h2 id="3">Exporting the SVG from Adobe Illustrator</h2>
        <p>Exporting the SVG from Illustrator is as simple as it sounds, however there are a few settings that should be checked.</p>
        <ol>
            <li>In Adobe Illustrator, navigate to FILE &gt; Export &gt; Export As...</li>
            <li>Choose where to export your file to on your computer.</li>
            <li>Checking "Use Artboard" will crop the export at the edges of the artboard you're using for the map.</li>
            <li>Click "Export"</li>
            <li>In the SVG Options dialog, the following settings must be set:
                <ol>
                    <li>Styling: Internal CSS</li>
                    <li>Font: SVG</li>
                    <li>Images: Preserve</li>
                    <li>Object IDs: Layer Names</li>
                    <li>Decimal: 2</li>
                    <li>Minify: YES</li>
                    <li>Responsive: NO</li>
                </ol>
            </li>
            <li>Export your map.</li>
        </ol>
        </>
    )
}