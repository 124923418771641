export const getAll = (setMaps) =>{
    let db;
    const openReq = indexedDB.open('caveView')
    openReq.onupgradeneeded = (e)=>{
       db = e.target.result;
       db.createObjectStore('caveviewmaps',{keyPath:"index"})
   }
   openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["caveviewmaps"], "readwrite");

    const store = tx.objectStore('caveviewmaps');

    const request = store.getAll()
   
    request.onsuccess = (e) =>{ 

        const myr = request.result
        setMaps(myr)
    }
};

}

export const addMap = (dbEntry) =>{
    let db;
    const openReq = indexedDB.open('caveView')
    
    openReq.onupgradeneeded = (e)=>{
        db = e.target.result;
        db.createObjectStore('caveviewmaps',{keyPath:"index"})
    }
    openReq.onsuccess = e =>{
        db = e.target.result;
        const tx = db.transaction(["caveviewmaps"],"readwrite")
        const store = tx.objectStore("caveviewmaps")
        store.add(dbEntry)
       
   
    }
    openReq.onerror = e=>{
       
   
    }
    return {dbEntry:dbEntry}
}

export const deleteMap = (key) =>{
let db;
const openReq = indexedDB.open('caveView')
openReq.onsuccess = (e) =>{
    db=e.target.result
    const tx = db.transaction(["caveviewmaps"],"readwrite")
    const delReq = tx.objectStore("caveviewmaps").delete(key)
    tx.oncomplete = ()=> document.querySelector(`#mapEntry${key}`).remove()
}
}

export const refreshMap = (key) =>{

}
export const getMap = (key) =>{
    let db;
    const openReq = indexedDB.open('caveView')
    openReq.onupgradeneeded = (e)=>{
       db = e.target.result;
       db.createObjectStore('caveviewmaps',{keyPath:"index"})
   }
   openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["caveviewmaps"], "readwrite");

    const store = tx.objectStore('caveviewmaps');

    const request = store.get(key)
   
    request.onsuccess = (e) =>{ 

        const myr = request.result
        return myr
    }
}
}