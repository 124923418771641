import React from "react";
import ReactDOM from 'react-dom/client';
import './AreYouSure.css'
import { deleteMap } from "../../utils/indexedDB";
const AreYouSureModal = (props) =>{

    
    const {action,target,index} = props
    const exit = ()=>{
        document.querySelector("div.areYouSureModalContainer").remove()
    }
    const confirmDelete = (index)=>{

        deleteMap(index)
        document.querySelector("div.areYouSureModalContainer").remove()
    }
    return(
        <div className="areYouSureModal">
            <div>Are you sure you want to {action} {target}?</div>
            <div>This action is permanent and will require a complete reloading of the map.</div>
            <div>
                <button className="yes" onClick={()=>confirmDelete(index)}>DELETE</button>
                <button className="no" onClick={()=>exit()}>NO</button>
            </div>
        </div>
    )
}


export const launchAreYouSureModal = (props) =>{
    const {action,target,index} = props
    const root = document.getElementById("root")
    const conatiner = document.createElement("div")
    conatiner.className = "areYouSureModalContainer"
    root.appendChild(conatiner)
    const spot = ReactDOM.createRoot(conatiner);
    spot.render(<AreYouSureModal index={index} action={action} target={target}/>);

}