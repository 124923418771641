import React from 'react';
import './style.css'
function TitleBlock(props) {
    const {data,dataStr} = props
    let surveyors = "N/A"
    if(data.surveyors) surveyors = data.surveyors.split(", ").map((surveyor,i)=>{
      return(<div key={i} className="titleBlock_surveyor">{surveyor}</div>)
    })
  return (
    <div id="subMenu_title" className={`subMenu closed"`}>
       <div className="subMenuContents">
        <div className="titleBlock_title">{data.title}</div>
      <div className="titleBlock_location">{data.location}</div>
      <div className="titleBlock_date">{data.date}</div>
      <div className="titleBlock_version">Digital Map - Version {data.v}</div>
      <div className="titleBlock_lede top"><div className="lede">Length:</div>{data.length} feet ({Math.round(Number(data.length/5280*1000))/1000} miles)</div>
      <div className="titleBlock_lede"><div className="lede">Depth:</div>{data.depth} feet </div>
      <div className="titleBlock_lede top"><div className="lede">Data Reduction:</div>{data.datared}</div>
      <div className="titleBlock_lede"><div className="lede">Cartography:</div>{data.datared}</div>
      <div className="titleBlock_surveyHeader">{data.surveyhead}:</div>
      <div className="titleBlock_surveyors">{surveyors}</div>
      <div className="titleBlock_geology">{data.geology}</div>
      <div className="titleBlock_copyright">{data.copyright}</div>
      <div className="titleBlock_dataStr">Raw data string:{dataStr}</div>
       </div>
      
     
    </div>
    
  );
}

export default TitleBlock;