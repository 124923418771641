import React from "react";
import ReactDOM from 'react-dom/client';
import '../../pages/style.css'
const ErrorModal = (props) =>{
    const returnString = ()=>{
        switch (props.direction){
            case "/": return "Return to My Maps";
            default: return "Return to My Maps";
        }
    }
    const handleReturn = () => window.location.pathname = props.direction

    return(
        <div className={`errorModal ${props.type}`}>
            <div className="errorModalHeader">{props.type}</div>
            <div className="errorModalMessage">{props.message}</div>
            <button className={props.type} onClick={()=>handleReturn()}>{returnString()}</button>
        </div>
    )
}

export const launchErrorModal = (type,message,direction) =>{
    const root = document.getElementById("root")
        const conatiner = document.createElement("div")
        conatiner.className = "bodyContainer"
        conatiner.id="refreshContainer"
        root.appendChild(conatiner)
        const spot = ReactDOM.createRoot(conatiner);
        spot.render(<ErrorModal type={type} message={message} direction={direction}/>)
}